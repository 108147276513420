
<template>
  <div class="sidebar-container has-logo">
    <logo />
    <div class="el-scrollbar-body">
        <el-scrollbar wrap-class="scrollbar-wrapper">
            <div class="el-scrollbar__view">
                <ul role="menubar" class="el-menu">
                    <div v-for="menu in menuList" :key="menu.id" @click="handleMenuClick(menu.id)">
                        <router-link :to="menu.path" :class="menu.isActive ? 'router-link-exact-active router-link-active': 'router-link-exact router-link'"> 
                            <li class="el-menu-item submenu-title-noDropdown" :class="menu.isActive ? 'is-active':''">
                                <span class="el-icon">
                                    <img :src="menu.isActive ? menu.iconAcrive : menu.icon" />
                                </span>
                                <span>{{menu.name}}</span>
                            </li>
                        </router-link>
                    </div>
                </ul>
            </div>
        </el-scrollbar>

        <div class="menu-bottom">
            <ul class="bottom-ul">
                <li>
                    <div class="items"><!--  @click="handleSet" -->
                        <div class="itemImg"><img :src="setUrl" /></div>
                        <span class="itemtext">{{setName}}</span>
                    </div>
                </li>
                <li>
                    <div class="items"><!-- @click="handleSys" -->
                        <div class="itemImg"><img :src="sysUrl" /></div>
                        <span class="itemtext">{{sysName}}</span>
                    </div>
                </li>
                <li>
                    <div class="items">
                        <div class="itemImg"><img :src="msgUrl" /></div>
                        <span class="itemtext">{{msgName}}</span>
                    </div>
                    <!-- <div><el-badge class="mark" :value="2" /></div>  -->
                </li>
                <!-- <li>
                    <div class="items">
                        <div class="itemImg"><img :src="userUrl" /></div>
                        <span class="itemtext">{{userName}}</span>
                    </div>
                </li> -->
            </ul>
        </div>
    </div>
  </div>
</template>

<script>
import Logo from './Logo'

export default {
  components: { Logo },
  data() {
    return {
        menuList: [{
            id: 1,
            name: '知识问答',
            icon: require('@/assets/icon/icon_home.png'),
            iconAcrive: require('@/assets/icon/icon_home_active.png'),
            path: '/',
            isActive: false
        },
        // {
        //     id: 2,
        //     name: '排故助手',
        //     icon: require('@/assets/icon/icon_pg.png'),
        //     iconAcrive: require('@/assets/icon/icon_pg_active.png'),
        //     path: '/trouble',
        //     isActive: false
        // },
        {
            id: 3,
            name: '知识查询',
            icon: require('@/assets/icon/icon_lc.png'),
            iconAcrive: require('@/assets/icon/icon_lc_active.png'),
            path: '/tree',
            isActive: false
        },
        {
            id: 4,
            name: '知识探索',
            icon: require('@/assets/icon/icon_zs.png'),
            iconAcrive: require('@/assets/icon/icon_zs_active.png'),
            path: '/planet',
            isActive: false
        },
    ],
        setUrl: require('@/assets/icon/icon_set.png'),
        setName: '设置',
        sysUrl: require('@/assets/icon/icon_dm.png'),
        sysName: '关于',
        msgUrl: require('@/assets/icon/icon_msg.png'),
        msgName: '消息',
        userUrl: require('@/assets/icon/icon_user.png'),
        userName: '一部小航'
    }
  },
  methods: {
      handleMenuClick(id) {
         this.menuList.forEach(item => {
             if(item.id === id) {
                 item.isActive = true
             } else {
                 item.isActive = false
             }
         })
      },
      handleSet() {
          this.$parent.dialogSysVisible = false
          this.$parent.dialogPdfVisible = false
          this.$parent.dialogSetVisible = true
      },
      handleSys() {
          this.$parent.dialogSetVisible = false
          this.$parent.dialogPdfVisible = false
          this.$parent.dialogSysVisible = true
      }
  }
}
</script>

<style lang="scss" scoped>
.el-icon {
    display: inline-block;
    width: 28px;
    height: 28px;
    margin: 15px 25px 10px 15px;
}
.el-menu-item {
    font-size: 16px;
    color: #4b5867;
}
.el-menu-item * {
    vertical-align: top;
}
.el-scrollbar-body {
    height: calc(100% - 183px);
}
.bottom-ul {
    padding: 10px 0 0;
    border-top: 1px solid #bcbfcd;
    margin: 0 30px 0 18px;
}
.bottom-ul li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
    font-size: 14px;
    color: #4b5867;
    .items {
        display: flex;
        align-items: center;
        cursor: pointer;
        .itemImg {
            width: 50px;
            text-align: center;
            margin-right: 20px;
            img {
                position: relative;
                top: 3px;
            }
        }
    }
}
</style>