<template>
    <el-dialog
        :close-on-click-modal="false"
        :visible.sync="dialogSysVisible"
        title=""
        width="80%"
        custom-class="info-dialog"
        center
        >
        <div class="dialog-head">
            <el-button class="el-dialog__headerbtn" @click="handleCloseSys"><i class="el-dialog__close el-icon el-icon-close"></i></el-button>
        </div>
        <div class="dialog-body">
            <iframe :src="iframeUrl" width="100%" :height="getHeigh()" frameborder="0"></iframe>
        </div>
    </el-dialog>
</template>

<script>
  export default {
    name: 'SetDialog',
    props: {
      dialogSysVisible: {type: Boolean, required: true}
    },
    data() {
      return {
          iframeUrl: '../pdf/web/viewer.html?file='+ encodeURIComponent('demo.pdf') + '&text=',
          height: 0
      }
    },
    methods: {
        getHeigh() {
            let dom = document.querySelector(".mainContainer")
            if(dom)
            return dom.clientHeight - 30
        },
        handleCloseSys() {
            this.$parent.dialogSysVisible = false
        }
    }
  }
</script>
<style scoped>
/deep/.dialog-head {
    position: relative;
    height: 0;
}
/deep/.el-dialog__header {
    display: none;
}
/deep/.info-dialog {
    width: calc(100% - 280px) !important;
    height: calc(100% - 40px);
    margin: 20px 0 0 260px !important;
    border-radius: 20px;
}
/deep/.dialog-head .el-dialog__headerbtn {
    position: absolute;
    top: -21px;
    right: -11px;
    padding: 0;
    background: 0 0;
    border: none;
    outline: 0;
    cursor: pointer;
    font-size: 16px;
}
</style>