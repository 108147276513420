<template>
    <el-dialog
      :close-on-click-modal="false"
      :visible.sync="dialogSetVisible"
      title=""
      width="30%"
      custom-class="add-dialog"
      center
    >
        <div class="dialog-body">
            <div class="dialog-row">
                <div class="dialog-row-title">温度</div>
                <div class="dialog-row-tit">用于调整大模型生成稳定度</div>
                <div class="dialog-row-right" style="flex:1">
                    <el-progress :percentage="temperature" :format="format"></el-progress>
                </div>
            </div>
            <div class="dialog-row">
                <div class="dialog-row-title">LLM选择</div>
                <div class="dialog-row-tit">用于选择基石大模型</div>
                <div class="dialog-row-right">
                    <el-select v-model="llmtag" size="mini" placeholder="请选择">
                      <el-option
                        v-for="item in llmList"
                        :key="item.id"
                        :label="item.name"
                        :value="item.name">
                      </el-option>
                    </el-select>
                </div>
            </div>
            <div class="dialog-row">
                <div class="dialog-row-title">历史对话轮数</div>
                <div class="dialog-row-tit">记录历史对话信息的轮次</div>
                <div class="dialog-row-right">
                    <el-input v-model="historyNum" size="mini" />
                </div>
            </div>
            <div class="dialog-row">
                <div class="dialog-row-title">存储对话记录</div>
                <div class="dialog-row-tit">是否存储对话记录，用于后续训练，提高效果</div>
                <div class="dialog-row-right">
                    <el-switch
                        v-model="chatValue"
                        active-color="#4d82ff"
                        inactive-color="#b7bbc2"
                        active-value="100"
                        inactive-value="0">
                    </el-switch>
                </div>
            </div>
            <div class="dialog-row">
                <div class="dialog-row-title">Embedding</div>
                <div class="dialog-row-tit">Embedding方式选择</div>
                <div class="dialog-row-right">
                    <el-select v-model="emdtag" size="mini" placeholder="请选择">
                      <el-option
                        v-for="item in embList"
                        :key="item.id"
                        :label="item.name"
                        :value="item.name">
                      </el-option>
                    </el-select>
                </div>
            </div>
            <div class="dialog-row">
                <div class="dialog-row-title">similarity</div>
                <div class="dialog-row-tit">计算similarity的方法</div>
                <div class="dialog-row-right">
                    <el-select v-model="simitag" size="mini" placeholder="请选择">
                      <el-option
                        v-for="item in simiList"
                        :key="item.id"
                        :label="item.name"
                        :value="item.name">
                      </el-option>
                    </el-select>
                </div>
            </div>
            <div class="dialog-row">
                <div class="dialog-row-title">展示思维链</div>
                <div class="dialog-row-tit">用于展示模型的思考过程</div>
                <div class="dialog-row-right">
                    <el-switch
                        v-model="swlValue"
                        active-color="#4d82ff"
                        inactive-color="#b7bbc2"
                        active-value="100"
                        inactive-value="0">
                    </el-switch>
                </div>
            </div>
        </div>
        <div slot="footer" class="dialog-footer" style="text-align: center">
            <el-button
            type="default"
            size="small"
            icon="el-icon-orange"
            @click="cancelClick()"
            >取消</el-button
            >
            <el-button
            type="primary"
            size="small"
            icon="el-icon-search"
            @click="saveClick()"
            >确定</el-button>
      </div>
    </el-dialog>
</template>

<script>
  export default {
    name: 'SetDialog',
    props: {
      dialogSetVisible: {type: Boolean, required: true}
    },
    data() {
      return {
        temperature: 60,
        llmtag: 'ChatGLM2-6B',
        llmList: [{
            id:1, name:'ChatGLM2-6B', vlaue: 'ChatGLM2-6B'
        },{
            id:2, name:'Baichuan-7B', vlaue: 'Baichuan-7B'
        },{
            id:3, name:'LLaMA-13B', vlaue: 'LLaMA-13B'
        }],
        emdtag: 'ernie-3.0-nano-zh',
        embList: [{
            id:1, name:'ernie-3.0-nano-zh', vlaue: 'ernie-3.0-nano-zh'
        },{
            id:2, name:'m3e-base', vlaue: 'm3e-base'
        },{
            id:3, name:'text2vec', vlaue: 'text2vec'
        }],
        simitag: 'Euclidean Distance',
        simiList:[{
            id:1, name:'Euclidean Distance', vlaue: 'Euclidean Distance'
        },{
            id:2, name:'Cosine Similarity', vlaue: 'Cosine Similarity'
        },{
            id:3, name:'Inner Product', vlaue: 'Inner Product'
        }],
        historyNum: 3,
        chatValue: '100',
        swlValue: '100'
      }
    },
    methods: {
        saveClick() {
            this.$parent.dialogSetVisible = false
        },
        cancelClick() {
            this.$parent.dialogSetVisible = false
        },
        format(percentage) {
            return percentage === 100 ? '满' : `${percentage}%`;
        }
    }
  }
</script>
<style scoped>
/deep/.el-dialog__header {
    display: none;
}
.dialog-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 10px;
}
.dialog-row-title {
    width: 112px;
    font-size: 16px;
}
.dialog-row-tit {
    flex: 1;
    font-size: 12px;
    color: #999;
}
/deep/.el-radio-button__orig-radio:checked+.el-radio-button__inner {
    background-color: rgb(77, 130, 255);
    border-color:rgb(77, 130, 255);
    -webkit-box-shadow: -1px 0
}
/deep/.el-input--mini {
    max-width: 115px;
}
/deep/.el-progress {
    width: 115px;
    float: right;
}
/deep/ .el-progress__text {
    font-size: 12px !important;
    line-height: 20px;
    float: right;
}
/deep/.el-progress-bar {
    padding-right: 30px;
}
/deep/.el-progress-bar__inner {
    background-color: #4d82ff;
}
</style>